import { Avatar, Box, Button, Stack, Typography } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";

import axiosInstance from "../utils/axiosConfig";
import formatNumberShort from "../utils/formatNumberShort";
import DataTable from "./DataTable";
import starSvg from "../assets/Star.svg";
import CustomConfirmationDialog from "./CustomConfirmationDialog";
import SwipeableDrawerComponent from "./SwipeableDrawerComponent ";
import DetailsSidePanel from "./DetailsSidePanel";
import RatingAndReviewSidePanel from "./RatingAndReviewSidePanel";
import useDebounce from "../hooks/useDebounce";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import {
  setDriverDetails,
  setPage,
  setRatingAndReviewSidePanel,
} from "../state/ratingAndReviewSidePanelSlice";
import { clearFilters } from "../state/ratingAndReviewSidePanelFilterSlice";
import SearchActionComponent from "./reusableComponents/SearchActionComponent";
import { BootstrapTooltip } from "./DetailsSidePanelChart";
import usePermission from "../hooks/usePermission";
import { useSettings } from "../hooks/useSettings";
import CustomFilter from "./reusableComponents/Filter/CustomFilter";
import FilteredResults from "./FilteredResults";
import { removeQuery, setQuery } from "../utils/queryHelpers";
import CommonTableHead from "./CommonTableHead";
import { notifyToast } from "../utils/notify";

const GenericText = ({ variant = "body1", text, css = "" }) => {
  const typoStyles = {
    color: "var(--text-dark, #000)",
    fontFamily: "Proxima Nova",
    fontSize: "13px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
    letterSpacing: "0.26px",
    textTransform: "capitalize",
  };
  return (
    <BootstrapTooltip title={text}>
      <Typography variant={variant} sx={css ? { ...css } : { ...typoStyles }}>
        {text}
      </Typography>
    </BootstrapTooltip>
  );
};

const ToggleButton = ({
  toggledrawer = () => () => {},
  setid = () => {},
  id,
  text,
  drawer,
}) => {
  const textCss = {
    color: "#2d5a9b",
    fontFamily: "Proxima Nova",
    fontSize: "13px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
    letterSpacing: "0.26px",
    textTransform: "capitalize",
  };
  const [searchParams, setSearchParams] = useSearchParams();
  return (
    <Button
      variant="text"
      sx={{
        width: "auto",
        justifyContent: "flex-start",
        overflow: "hidden",
      }}
      onClick={(e) => {
        setid(id);
        // this  toggledrawer funtion return a funtion then we are passing event on it
        setQuery(drawer, true, setSearchParams);
        setQuery("id", id, setSearchParams);
        toggledrawer("right", true)(e);
      }}
    >
      <GenericText text={text} css={textCss} />
    </Button>
  );
};

const RatingAndReview = () => {
  const [isLoading, setisLoading] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  // Id stored in search query

  const id = searchParams.get("id");
  // State for managing the search query
  const [search, setSearch] = useState("");

  // getting auth object from redux store
  const auth = useSelector((state) => state.auth);

  const serachDebounce = useDebounce(search, 500);

  // state to store ratings & reviews data
  const [ratingsAndReviews, setRatingsAndReviews] = useState([]);

  const [ratingAndReviewId, setRatingAndReviewId] = useState(id || "");

  // state to store ratings & reviews total count
  const [totalcount, setTotalCount] = useState("");

  const page = parseInt(searchParams.get("page"), 0) || 0;
  const limit = parseInt(searchParams.get("limit"), 15) || 15;

  // control mui pagination
  const [paginationModel, setPaginationModel] = React.useState({
    page: page || 0,
    pageSize: limit || 15,
  });

  const { timezone, dateformat, formatDate, timeFormat } = useSettings();

  // filter state
  const [ratings, setRatings] = useState("");
  const [carType, setCarType] = useState("");
  const [tierLevel, setTierLevel] = useState("");
  const permissions = usePermission();

  const { ratingAndReviewSidePanel } = useSelector(
    (store) => store.ratingAndReviewSidePanel
  );

  // state to store delete id
  const [deleteId, setDeleteId] = useState("");

  const [showDelete, setShowDelete] = useState(false);

  let ratingDrawer = searchParams.get("ratingDrawer");
  let BookingDrawer = searchParams.get("BookingDrawer");
  // State to track the starting position of a swipeable component mui
  const [showRatingAndreviewsPanel, SetShowRatingAndreviewsPanel] = useState({
    right: ratingDrawer || false,
  });

  // State to track the starting position of a swipeable component mui
  const [showFilter, setShowFilter] = useState({
    right: false,
  });

  const [showDetail, setShowDetail] = useState({
    right: BookingDrawer || false,
  });

  // using dipatch tigger action
  const dispatch = useDispatch();

  const [riderId, setRiderId] = useState(id || "");

  // state to manage sidepanel data
  const [ratingAndReviewData, setRatingAndReviewData] = useState([]);

  // checking if user is Authorized
  const isAuthorized = auth;

  // ---- funtion ----

  // Funtion to Builds and returns a query
  const buildQueryString = (download = false) => {
    const queryParameters = [];

    if (serachDebounce && search) {
      queryParameters.push(`search=${serachDebounce}`);
    }

    if (ratings) {
      queryParameters.push(`rating=${ratings}`);
    }

    if (carType) {
      queryParameters.push(`car_type=${carType}`);
    }

    if (tierLevel) {
      queryParameters.push(`tier_level=${tierLevel}`);
    }

    const queryString =
      queryParameters.length > 0 ? `&${queryParameters.join("&")}` : "";

    return queryString;
  };

  // funtion to get ratings and reviews data
  const getRatingsAndReviews = async () => {
    setisLoading(true);
    const query = buildQueryString();
    let url = `/crm/reviews/?page=${paginationModel.page + 1}&limit=${
      paginationModel.pageSize
    }${query}`;
    try {
      let response = await axiosInstance.get(url);

      if (response) {
        setRatingsAndReviews(response?.data?.data?.records ?? []);
        setTotalCount(response?.data?.data?.total_count ?? 0);
      }
    } catch (error) {
      notifyToast.error("Error", error);
    } finally {
      setisLoading(false);
    }
  };

  // funtion to delete rating
  const deleteRating = async () => {
    const url = `/crm/reviews/${deleteId}/`;
    try {
      let response = await axiosInstance.delete(url);
      if (response) {
        notifyToast.success("Success", "delete successfully");
        setDeleteId("");
        getRatingsAndReviews();
      }
    } catch (error) {
      notifyToast.error("Error", error);
    }
  };

  // mui toggleDrawer funtion for side panel
  const createToggleDrawer = (setState) => (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState((prev) => ({ ...prev, [anchor]: open }));
  };

  // instance of toggle Drawer
  const toggleRatingAndReviewsPanel = (anchor, open) => (event) => {
    dispatch(setPage(1));

    if (ratingAndReviewData.length >= 1) {
      setRatingAndReviewData([]);
    }

    dispatch(setDriverDetails({ driverName: "", driverTier: "", cabType: "" }));

    dispatch(clearFilters());

    if (ratingAndReviewSidePanel) {
      dispatch(setRatingAndReviewSidePanel(false));
    }

    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    SetShowRatingAndreviewsPanel((prev) => ({ ...prev, [anchor]: open }));
  };

  const toggleDetailsPanel = (anchor, open) => (event) => {
    if (riderId) {
      setRiderId("");
    }

    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setShowDetail((prev) => ({ ...prev, [anchor]: open }));
  };

  const toggleFilterDrawer = createToggleDrawer(setShowFilter);

  // dataGrid colums
  const columns = [
    {
      field: "booking_id",
      headerName: "Booking Id",
      flex: 1,
      renderCell: (params) => {
        if (!params.value) {
          return "--";
        }

        return (
          <ToggleButton
            text={params.value}
            setid={setRiderId}
            id={params?.row?.ride_id}
            toggledrawer={toggleDetailsPanel}
            drawer={"BookingDrawer"}
          />
        );
      },
    },
    {
      field: "driver_name",
      headerName: "Driver Name",
      flex: 1,
      renderCell: (params) => {
        if (!params?.value) {
          return "--";
        }

        return (
          <ToggleButton
            toggledrawer={toggleRatingAndReviewsPanel}
            setid={setRatingAndReviewId}
            id={params?.row?.driver_id ?? ""}
            text={params.value}
            drawer={"ratingDrawer"}
          />
        );
      },
    },
    {
      field: "driver_nickname",
      headerName: "Driver nick name",
      flex: 1,
      renderCell: (params) => {
        if (!params?.value) {
          return "--";
        }

        return (
          <ToggleButton
            toggledrawer={toggleRatingAndReviewsPanel}
            setid={setRatingAndReviewId}
            id={params?.row?.driver_id ?? ""}
            text={params.value}
            drawer={"ratingDrawer"}
          />
        );
      },
    },
    {
      field: "driver_case_id",
      headerName: "LTFRB Case ID",
      flex: 1,
      renderCell: (params) => {
        if (!params?.value) {
          return "--";
        }

        return <GenericText text={params?.value ?? ""} />;
      },
    },
    {
      field: "customer_name",
      headerName: "Customer Name",
      flex: 1,
      renderCell: (params) => {
        if (!params?.value) {
          return "--";
        }

        return <GenericText text={params.value} />;
      },
    },
    {
      field: "customer_nickname",
      headerName: "Customer nick name",
      flex: 1,
      renderCell: (params) => {
        if (!params?.value) {
          return "--";
        }

        return <GenericText text={params.value} />;
      },
    },
    {
      field: "created_at",
      headerName: `Date(${dateformat})`,
      flex: 1,
      formatter: {
        created_at: (params) => formatDate(params?.value, timezone, dateformat),
        time: (params) =>
          formatDate(params?.row.created_at, timezone, timeFormat),
        rating: (params) => `(${params?.value})`,
        // Add other formatters as needed
      },
      renderCell: (params) => {
        if (!params.value) {
          return "--";
        }

        return (
          <GenericText text={formatDate(params?.value, timezone, dateformat)} />
        );
      },
    },
    {
      field: "time",
      headerName: "time",
      flex: 1,
      renderCell: (params) => {
        if (!params?.row?.created_at) {
          return "--";
        }

        const time = formatDate(
          params?.row?.created_at ?? "",
          timezone,
          timeFormat
        );
        return <GenericText text={time} />;
      },
    },
    {
      field: "cab_type",
      headerName: "Cab type",
      flex: 1,
      renderCell: (params) => {
        if (!params.value) {
          return "--";
        }

        return <GenericText text={params.value} />;
      },
    },
    {
      field: "driver_tier",
      headerName: "Tier level",
      flex: 1,
      renderCell: (params) => {
        if (!params.value) {
          return "--";
        }

        return <GenericText text={params?.value} />;
      },
    },
    {
      field: "rating",
      headerName: "Rating",
      flex: 1,
      renderCell: (params) => {
        if (!params.value) {
          return "--";
        }

        return (
          <Stack direction={"row"} gap="5px" alignItems={"center"}>
            <Avatar
              src={starSvg}
              sx={{
                width: "16px",
                height: "16px",
              }}
            />

            <Typography>{`(${params.value})`}</Typography>
          </Stack>
        );
      },
    },
    {
      field: "review",
      headerName: "Reviews",
      flex: 1,
      renderCell: (params) => {
        if (!params.value) {
          return "--";
        }

        return (
          <BootstrapTooltip title={params.value}>
            <Typography
              sx={{
                flexShrink: 0,
                overflow: "hidden",
                color: "var(--text-dark, #000)",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                fontFamily: "Proxima Nova, sans-serif",
                fontSize: 12,
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "normal",
                letterSpacing: "0.24px",
              }}
            >
              {params.value}
            </Typography>
          </BootstrapTooltip>
        );
      },
    },
    // isAuthorized && {
    //   field: "action",
    //   headerName: "Action",
    //   flex: 0.5,
    //   renderCell: (params) => {
    //     return (
    //       <IconButton
    //         aria-label="Delete"
    //         onClick={() => {
    //           setDeleteId(params.id);
    //           setShowDelete(true);
    //         }}
    //       >
    //         <DeleteIcon />
    //       </IconButton>
    //     );
    //   },
    // },
  ];

  const filterOptions = [
    {
      summryText: "Car Type",
      clearHandler: () => {},
      state: carType,
      setState: setCarType,
      options: [
        {
          value: "COMPACT",
          label: "Compact",
        },
        {
          label: "Taxi",
          value: "TAXI",
        },
        {
          value: "PLUS",
          label: "Plus",
        },
        {
          value: "EXECUTIVE",
          label: "Executive",
        },
      ],
    },
    {
      summryText: "Tier level",
      clearHandler: () => {},
      state: tierLevel,
      setState: setTierLevel,
      options: [
        { label: "Classic", value: "classic" },
        { label: "Classic+", value: "CLASSIC_PLUS" },
        { label: "Pro", value: "pro" },
        { label: "Elite", value: "elite" },
      ],
    },
    {
      summryText: "Rating",
      clearHandler: () => {},
      state: ratings,
      setState: setRatings,
      options: [
        {
          value: "5",
          label: "5 star",
        },
        {
          value: "4",
          label: "4 star",
        },
        {
          value: "3",
          label: "3 star",
        },
        {
          value: "2",
          label: "2 star",
        },
        {
          value: "1",
          label: "1 star",
        },
      ],
    },
  ];

  const allowed_sub_sections = permissions?.SUB_SECTIONS ?? ["rating"];

  const sub_section_to_label = {
    rating: "Rating",
  };

  const tableHeadButtonConfig = allowed_sub_sections?.map((sub_section) => ({
    label: sub_section_to_label[sub_section],
    value: "rating",
    totalcount: totalcount,
    onClick: (value) => {},
  }));

  const buttonConfig = {
    active: "rating",
    totalcount: totalcount,
  };

  useEffect(() => {
    // fetching ratings & reviews data

    getRatingsAndReviews();
  }, [
    serachDebounce,
    ratings,
    carType,
    tierLevel,
    paginationModel.page,
    paginationModel.pageSize,
  ]);
  return (
    <>
      <Box
        sx={{
          margin: "20px",
        }}
      >
        <SearchActionComponent
          value={search}
          setState={setSearch}
          toggleDrawer={toggleFilterDrawer}
          placeholder={"Search Driver, Rating"}
        />

        <FilteredResults options={filterOptions} />

        <CommonTableHead
          ButtonConfig={tableHeadButtonConfig}
          active={buttonConfig?.active}
          totalcount={buttonConfig?.totalcount}
        />

        <SwipeableDrawerComponent
          isOpen={showDetail["right"]}
          onClose={(e) => {
            removeQuery("BookingDrawer", setSearchParams);
            removeQuery("id", setSearchParams);

            toggleDetailsPanel("right", false)(e);
          }}
          anchor="right"
        >
          <DetailsSidePanel
            toggleDrawer={toggleDetailsPanel}
            DId={riderId}
            setDriverId={setRiderId}
            specialFilter={"both_ticket"}
            anchor="right"
          />
        </SwipeableDrawerComponent>

        <SwipeableDrawerComponent
          isOpen={showFilter["right"]}
          onClose={(e) => {
            toggleFilterDrawer("right", false)(e);
          }}
          anchor="right"
        >
          <CustomFilter
            filterOptions={filterOptions}
            toggleDrawer={() => toggleFilterDrawer("right", false)}
          />
        </SwipeableDrawerComponent>

        <SwipeableDrawerComponent
          isOpen={showRatingAndreviewsPanel["right"]}
          onClose={(e) => {
            removeQuery("ratingDrawer", setSearchParams);
            removeQuery("id", setSearchParams);
            toggleRatingAndReviewsPanel("right", false)(e);
          }}
          anchor="right"
        >
          <RatingAndReviewSidePanel
            ratingAndReviewId={ratingAndReviewId}
            toggleDrawer={toggleRatingAndReviewsPanel}
            ratingAndReviewData={ratingAndReviewData}
            setRatingAndReviewData={setRatingAndReviewData}
            showRatingAndreviewsPanel={showRatingAndreviewsPanel}
            isAuthorized={isAuthorized}
          />
        </SwipeableDrawerComponent>

        <DataTable
          row={ratingsAndReviews || []}
          columns={columns || []}
          loading={isLoading}
          paginationModel={paginationModel}
          setPaginationModel={setPaginationModel}
          totalCount={totalcount}
        />

        <CustomConfirmationDialog
          open={showDelete}
          setOpen={setShowDelete}
          status="delete"
          deleteById={deleteRating}
          message="Are you sure you want to delete this account?"
        />
      </Box>
    </>
  );
};

export default RatingAndReview;
