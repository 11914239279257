import {
  Avatar,
  Box,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useThrottle } from "../../hooks/useThrottle";
import peekupBlueCarIcon from "../../assets/PeekupBlueCar.svg";
import peekupOrangeCarIcon from "../../assets/PeekUpRedCar.svg";
import { SearchDropdown } from "../../components/SearchDropdown/SearchDropdown";
import driverRideStatusGreenIcon from "../../assets/driverRideStatusGreen.svg";
import driverRideStatusBlackIcon from "../../assets/driverRideStatusBlack.svg";
import {
  ShowCustomerDetails,
  updateInfoWindowContent,
} from "../../utils/driveronmap";
import MapQuickFilter from "../MapQuickFilter";

const driverRideStatus = [
  {
    icon: driverRideStatusBlackIcon,
    fill: "#000000",
    text: "on Halt",
  },
  {
    icon: driverRideStatusGreenIcon,
    fill: "#009D0F",
    text: "Ongoing",
  },
];

//css

const titleText = {
  color: "var(--text-dark, #000)",
  fontFamily: "Proxima Nova",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "normal",
  letterSpacing: "0.32px",
  textTransform: "capitalize",
};

// config
const quickFilterConfig = [
  {
    label: "All",
    value: "all",
  },
  {
    label: "Taxi",
    value: "taxi",
  },
  {
    label: "Compact",
    value: "compact",
  },
  {
    label: "Plus",
    value: "plus",
  },
  {
    label: "Executive",
    value: "executive",
  },
];

const GoogleMap = ({ initialApiCoordinates, locationState }) => {
  const [map, setMap] = useState(null);
  const [sebsetMarker, setSubSetMarker] = useState({});
  const [quickfilter, setQuickFilter] = useState(() => ["all"]);
  const checkref = useRef("all");
  const [customerMarker, setCustomerMarker] = useState();
  const [markers, setMarkers] = useState({});
  const [infoWindow, setInfoWindow] = useState(null);
  const [checkZoom, setCheckZoom] = useState({
    rider: false,
    driver: false,
  });
  const [search, setSearch] = useState("");
  const throttletext = useThrottle(search, 200);
  const [option, setoptions] = useState([]);
  const [select, setSelect] = useState("driverName");

  const selectOptions = [
    { value: "driverName", label: "Driver Name" },
    { value: "phoneNumber", label: "Phone Number" },
  ];

  const lat = locationState?.rider_location?.latitude
    ? parseFloat(locationState?.rider_location.latitude)
    : null;
  const lng = locationState?.rider_location?.longitude
    ? parseFloat(locationState?.rider_location?.longitude)
    : null;

  // Define car icons
  const carIcon = {
    url: peekupBlueCarIcon,
    scaledSize: new window.google.maps.Size(40, 40),
  };

  const greenCarIcon = {
    url: peekupOrangeCarIcon,
    scaledSize: new window.google.maps.Size(40, 40),
  };

  //Funtion To center

  const ZoomOnMap = (lat, lng) => {
    const bounds = new window.google.maps.LatLngBounds();
    bounds.extend(new window.google.maps.LatLng(lat, lng));
    map.fitBounds(bounds);
  };

  // funtion create marker
  const createMarker = (lat, lng, map, infoWindow) => {
    const position = {
      lat,
      lng,
    };
    const marker = new window.google.maps.Marker({
      position,
      map,
    });

    // Common logic for both cases (click, mouseover, mouseout)
    marker.addListener("click", async () => {
      ShowCustomerDetails(
        marker,
        infoWindow,
        map,
        locationState?.showRiderDetails
      );
    });

    marker.addListener("mouseover", () => {
      ShowCustomerDetails(
        marker,
        infoWindow,
        map,
        locationState?.showRiderDetails
      );
      infoWindow.open(map, marker);
    });

    marker.addListener("mouseout", () => {
      infoWindow.close();
    });

    setCustomerMarker(marker);
  };

  // Function to smoothly move the marker to a new location with direction
  const moveMarkerToWithDirection = (marker, newLat, newLng) => {
    const startLat = marker.getPosition().lat();
    const startLng = marker.getPosition().lng();
    // const bearing = calculateBearing(
    //   { lat: startLat, lng: startLng },
    //   { lat: newLat, lng: newLng }
    // );
    const startTime = new Date().getTime();
    const duration = 1000; // Animation duration in milliseconds

    function animate() {
      const currentTime = new Date().getTime();
      const progress = (currentTime - startTime) / duration;

      if (progress < 1) {
        const lat = startLat + progress * (newLat - startLat);
        const lng = startLng + progress * (newLng - startLng);
        marker.setPosition({ lat: lat, lng: lng });

        // Rotate the marker based on the bearing
        marker.setOptions({
          icon: {
            ...marker.getIcon(),
          },
        });

        requestAnimationFrame(animate);
      } else {
        marker.setPosition({ lat: newLat, lng: newLng });
      }
    }

    animate();
  };

  //checkbox handling function
  const handleTicketStatus = (e) => {
    const selectedValue = e.target.value;

    checkref.current = quickfilter[0];
    setQuickFilter([selectedValue]);
  };

  useEffect(() => {
    const initialCenter = {
      lat: lat || +process.env.REACT_APP_MAP_CENTER_LAT,
      lng: lng || +process.env.REACT_APP_MAP_CENTER_LNG,
    };

    const initialZoom = 12;

    let mapOptions = {
      center: initialCenter,
      zoom: initialZoom,
      mapTypeControl: false,
      gestureHandling: "cooperative",
      disableDefaultUI: true,
      zoomControl: true,
      fullscreenControl: true,
    };

    // Initialize the map
    const mapInstance = new window.google.maps.Map(
      document.getElementById("map"),
      mapOptions
    );

    const infoWindow = new window.google.maps.InfoWindow();

    // storing map in state so we don't need to render again and again
    setMap(mapInstance);
    setInfoWindow(infoWindow);
    createMarker(lat, lng, mapInstance, infoWindow, infoWindow);
  }, []); // Run once on component mount

  // useEffect(() => {
  //   // Update marker positions based on new data
  //   if (!map) return;

  //   const createOrUpdateMarker = (data, markers, map, infoWindow) => {
  //     let checkIsNew = false;
  //     const position = {
  //       lat: parseFloat(data.data.latitude),
  //       lng: parseFloat(data.data.longitude),
  //     };

  //     let marker = markers[data.data.id];

  //     if (locationState && !checkZoom.driver) {
  //       let driverData = markers?.find(
  //         (m) => m.id === locationState?.driver_id
  //       );
  //       if (!driverData && !checkZoom?.rider) {
  //         let rider_details = locationState?.rider_location;
  //         ZoomOnMap(
  //           parseFloat(rider_details?.latitude),
  //           parseFloat(rider_details?.longitude)
  //         );
  //         setCheckZoom((prev) => ({ ...prev, rider: true }));
  //       } else if (!checkZoom?.driver && driverData && locationState) {
  //         if (driverData?.id === locationState?.driver_id) {
  //           ZoomOnMap(position.lat, position.lng);
  //           setCheckZoom((prev) => ({ ...prev, driver: true }));
  //           customerMarker.setMap(null);
  //         }
  //       }
  //     }
  //     if (marker) {
  //       // Update existing marker
  //       marker.setIcon(data.data.status === "VACANT" ? greenCarIcon : carIcon);
  //       moveMarkerToWithDirection(marker, position.lat, position.lng);
  //     } else {
  //       // Create a new marker
  //       marker = new window.google.maps.Marker({
  //         position,
  //         map,
  //         icon: data?.data?.status === "VACANT" ? greenCarIcon : carIcon,
  //         id: data?.data?.id ?? "",
  //         driverName: data?.data?.full_name ?? "",
  //         phoneNumber: data?.data?.phone ?? "",
  //         bookingId: data?.data?.booking_id ?? "",
  //       });

  //       checkIsNew = true;

  //       console.log(`Marker ${marker.id} created`);
  //     }

  //     // Common logic for both cases (click, mouseover, mouseout)
  //     marker.addListener("click", async () => {
  //       updateInfoWindowContent(marker, infoWindow, map, data.data);
  //     });

  //     marker.addListener("mouseover", () => {
  //       updateInfoWindowContent(marker, infoWindow, map, data.data);
  //       infoWindow.open(map, marker);
  //     });

  //     marker.addListener("mouseout", () => {
  //       infoWindow.close();
  //     });

  //     return { isNew: checkIsNew, marker };
  //   };

  //   const updatedMarkers = Object.values(initialApiCoordinates).reduce(
  //     (acc, data) => {
  //       let driverData = createOrUpdateMarker(data, markers, map, infoWindow);

  //       if (driverData.isNew) {
  //         acc[data.data.id] = driverData.marker;
  //       } else {
  //         acc[data.data.id] = driverData.marker;
  //       }

  //       return acc;
  //     },
  //     {}
  //   );

  //   Object.entries(markers).forEach((res) => {
  //     const [id, value] = res;
  //     if (!initialApiCoordinates[id]) {
  //       value.setMap(null);
  //       console.log(`Marker removed for id: ${id}`);
  //     }
  //   });
  //   // Update state with the new markers
  //   setMarkers({ ...updatedMarkers });
  // }, [initialApiCoordinates, map]);

  const shouldDisplayMarker = (data) => {
    if (quickfilter[0] === "all") return true;

    return data.data.cab_type === quickfilter[0]?.toUpperCase();
  };

  useEffect(() => {
    if (!map) return;

    const createOrUpdateMarker = (data, markers, map, infoWindow) => {
      let checkIsNew = false;
      const position = {
        lat: parseFloat(data.data.latitude),
        lng: parseFloat(data.data.longitude),
      };

      let marker = markers[data.data.id];

      // Additional filtering logic
      if (!shouldDisplayMarker(data)) {
        if (marker) marker.setMap(null); // Remove marker if it exists
        return { isNew: false, marker: null };
      }

      if (locationState && !checkZoom.driver) {
        let driverData = markers?.find(
          (m) => m.id === locationState?.driver_id
        );
        if (!driverData && !checkZoom?.rider) {
          let rider_details = locationState?.rider_location;
          ZoomOnMap(
            parseFloat(rider_details?.latitude),
            parseFloat(rider_details?.longitude)
          );
          setCheckZoom((prev) => ({ ...prev, rider: true }));
        } else if (!checkZoom?.driver && driverData && locationState) {
          if (driverData?.id === locationState?.driver_id) {
            ZoomOnMap(position.lat, position.lng);
            setCheckZoom((prev) => ({ ...prev, driver: true }));
            customerMarker.setMap(null);
          }
        }
      }
      if (marker) {
        // Update existing marker
        marker.setIcon(data.data.status === "VACANT" ? greenCarIcon : carIcon);
        moveMarkerToWithDirection(marker, position.lat, position.lng);
      } else {
        // Create a new marker
        marker = new window.google.maps.Marker({
          position,
          map,
          icon: data?.data?.status === "VACANT" ? greenCarIcon : carIcon,
          id: data?.data?.id ?? "",
          driverName: data?.data?.full_name ?? "",
          phoneNumber: data?.data?.phone ?? "",
          bookingId: data?.data?.booking_id ?? "",
        });

        checkIsNew = true;

        console.log(`Marker ${marker.id} created`);
      }

      // Common logic for both cases (click, mouseover, mouseout)
      marker.addListener("click", async () => {
        updateInfoWindowContent(marker, infoWindow, map, data.data);
      });

      marker.addListener("mouseover", () => {
        updateInfoWindowContent(marker, infoWindow, map, data.data);
        infoWindow.open(map, marker);
      });

      marker.addListener("mouseout", () => {
        infoWindow.close();
      });

      return { isNew: checkIsNew, marker };
    };

    const updatedMarkers = Object.values(initialApiCoordinates).reduce(
      (acc, data) => {
        let driverData = createOrUpdateMarker(data, markers, map, infoWindow);

        if (driverData.marker) {
          acc[data.data.id] = driverData.marker;
        }

        return acc;
      },
      {}
    );

    Object.entries(markers).forEach((res) => {
      const [id, value] = res;
      if (!initialApiCoordinates[id]) {
        value.setMap(null);
        console.log(`Marker removed for id: ${id}`);
      }
    });

    setMarkers({ ...updatedMarkers });
  }, [initialApiCoordinates, map, quickfilter]);

  useEffect(() => {
    if ((throttletext === "") | (search.length === 0)) {
      setoptions([]);
    } else {
      let newsuggestion = Object.entries(markers).filter(([key, value]) => {
        const driverName = (value[select] || "")
          .split(" ")
          .join("")
          .trim()
          .toLowerCase();

        const isDriverNameMatch = driverName.includes(
          throttletext.toLowerCase()
        );

        return isDriverNameMatch;
      });
      setoptions(newsuggestion);
    }
  }, [throttletext]);

  return (
    <div>
      <Box sx={{ display: "flex", gap: "22px", marginBottom: "25px" }}>
        <MapQuickFilter
          initialApiCoordinates={initialApiCoordinates}
          handleTicketStatus={handleTicketStatus}
          quickfilter={quickfilter}
          quickFilterConfig={quickFilterConfig}
        />
      </Box>
      <Stack direction={"row"} justifyContent={"space-between"}>
        <Box>
          <Box sx={{ maxWidth: "480px", position: "relative" }}>
            <Box
              sx={{
                border: "1px solid #bcbaba",
                marginBottom: "18px",
                display: "flex",
                borderRadius: "12px",
              }}
            >
              <Select
                sx={{
                  border: "none",
                  outline: "none",
                  height: "48px",
                  background: "#fff",
                  borderRadius: "12px 0px 0px 12px",
                  "&:focus": {
                    bgcolor: "transparent", // Remove the focus background color
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "none", // Remove the border
                    borderRight: "1px solid gray",
                  },
                  fontSize: "12px",
                }}
                variant="outlined" // Ensure variant is outlined
                value={select}
                onChange={(e) => setSelect(e.target.value)}
              >
                {selectOptions.map(({ label, value }) => (
                  <MenuItem
                    sx={{
                      fontSize: "12px",
                    }}
                    key={label}
                    value={value}
                  >
                    {label}
                  </MenuItem>
                ))}
              </Select>
              <input
                type="text"
                placeholder="Search Driver, Phone no, Email"
                style={{
                  width: "300px",
                  height: "48px",
                  boxSizing: "border-box",
                  padding: "3px 10px",
                  border: "none",
                  outline: "none",
                  borderRadius: "0px 12px 12px 0px",
                }}
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </Box>
            {option.length > 0 && (
              <SearchDropdown
                suggestion={option}
                ZoomOnMap={ZoomOnMap}
                setSuggestion={setoptions}
                select={select}
              />
            )}
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
            padding: "10px",
          }}
        >
          <Typography sx={{ ...titleText, mr: "5px" }}>
            Driver Ride Status
          </Typography>

          {driverRideStatus.length > 0 &&
            driverRideStatus.map((res, index) => (
              <Stack
                key={index}
                direction={"row"}
                gap={"5px"}
                alignItems={"center"}
              >
                <Avatar
                  src={res.icon}
                  sx={{ fill: res.fill, height: "20px", width: "20px" }}
                />
                <Typography
                  sx={{
                    color: "var(--text-dark, #000)",
                    fontFamily: "Proxima Nova",
                    fontSize: "13px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "normal",
                    letterSpacing: "0.26px",
                  }}
                >
                  {res.text}
                </Typography>
              </Stack>
            ))}
        </Box>
      </Stack>

      <div id="map" style={{ width: "100%", height: "600px" }}></div>
    </div>
  );
};

export default GoogleMap;
